@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section10 {
  margin-bottom: 130px;
  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(100);
  }
}
.p-section10__title {
  height: 90px;
  margin-bottom: 75px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(84);
    margin-bottom: pxtovw(50);
  }
}

.p-section10__image {
  max-width: 100%;
  overflow-x: hidden;
}

.p-section10__logo-container {
  height: 398px;
  overflow: hidden;
  @include max-screen($breackpoint_md) {
    height: 273px;
  }
}
.p-section10__logo-list {
  height: 500px;
  padding: 0 16px;
  overflow-x: auto;
  @include min-screen(1600px) {
    width: 1540px;
    padding: 0;
    margin: auto;
  }
  img {
    width: 1540px;
    height: 398px;
    @include max-screen($breackpoint_md) {
      width: 1057px;
      height: 273px;
    }
  }
}

.p-section10__splide {
}
.p-section10__track {
  min-height: 273px;
}
.p-section10__list {
  height: 100%;
}
.p-section10__slide {
  min-width: 1540px;

  @include max-screen($breackpoint_md) {
    min-width: 1057px;
  }

  img {
    object-fit: cover;
    overflow: visible;
  }
}
