@charset 'utf-8';

@use '../foundation/global/_global' as *;

.l-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 125px;
  padding-right: 28px;

  @include max-screen($breackpoint_md) {
    display: none;
  }
}
.l-header__button {
  width: 238px;
  height: 84px;
  padding: 35px 20px;
  font-size: 15px;
  font-weight: 900;
}
