@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section05 {
  position: relative;
  padding-top: 100px;
  margin-bottom: 100px;
  background-color: #f0f0f0;
  background-position: center;
  background-size: min(100%, 1366px);
  @include background-image('index/section/05_bg-image.png');

  @include max-screen($breackpoint_md) {
    @include background-image('index/section/sp/05_bg-image.png');
    padding-top: pxtovw(50);
    margin-bottom: pxtovw(50);
    background-position: 0;
    background-size: cover;
  }
}
.p-section05__bg-image {
  position: absolute;
  z-index: -100;
}
.p-section05__inner {
  position: relative;
  @include max-screen($breackpoint_md) {
    padding: 0;
  }
}
.p-section05__title {
  height: 50px;
  margin-bottom: 50px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(70);
  }
}
.p-section05-cards {
  margin-bottom: 25px;

  @include max-screen($breackpoint_md) {
    display: none;
  }
}
.p-section05-cards__wrap {
  gap: 38px;
}
.p-section05-cards__card {
  width: calc(33.3% - (38px * 2 / 3));
}
.p-section05__image {
  max-width: 620px;
  margin-inline: auto;
  @include max-screen($breackpoint_md) {
    width: 100%;
    max-width: 300px;
    margin-right: 16px;
    margin-left: auto;
  }
}
.p-section05__splide {
  @include max-screen($breackpoint_md) {
    height: 331px;
    margin-bottom: 60px;
    overflow: hidden;
  }
}
.p-section05__track {
  padding: 10px;
  margin-bottom: pxtovw(65);
  @include max-screen($breackpoint_md) {
    padding: 0;
    margin: 0;
  }
}
.p-section05__list {
  @include max-screen($breackpoint_md) {
    display: flex !important;
    flex-wrap: nowrap;
    height: 400px;
    padding: 10px 0 20px !important;
    overflow-x: auto;
    > * {
      position: relative;
      margin-left: 20px;
      &:last-of-type {
        &::after {
          position: absolute;
          top: 0;
          left: 100%;
          width: 20px;
          height: 1px;
          content: '';
          background-color: transparent;
        }
      }
    }
  }
}
.p-section05__slide {
  max-width: 200px;

  img {
    border-radius: 15px;
    box-shadow: 1px 3px 10px rgba(#888888, 0.5);
  }
}

.p-section05__page {
  width: 11px;
  height: 11px;
  margin-inline: 8px !important;

  &.is-active {
    background: #333232;
    transform: scale(1) !important;
  }
}

.p-section05__pagination {
  bottom: -30px;
}
