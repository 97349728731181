@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section02 {
  position: relative;
  z-index: 1;
  margin-bottom: 123px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(100);
  }
}
.p-section02__title {
  max-width: 548px;
  margin-inline: auto;
}

.p-section02__content {
  margin-bottom: 75px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(48);
  }

  img {
    margin-bottom: 16px;
    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(30);
    }
  }
}

.p-section02__caption {
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    max-width: pxtovw(296);
    margin-inline: auto;
    font-size: pxtovw(12);
  }
}
.p-section02__button-wrap {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  margin-inline: auto;

  @include max-screen($breackpoint_lg) {
    flex-direction: column;
    align-items: center;
  }

  @include max-screen($breackpoint_md) {
    gap: pxtovw(20);
  }
}
.p-section02__button {
  width: 100%;
  max-width: 425px;
  height: 168px;

  @include max-screen($breackpoint_md) {
    max-width: pxtovw(330);
    height: pxtovw(100);
  }

  .c-button__text {
    font-size: 30px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  .c-button__text--red {
    font-size: 18px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;
      @include background-image('_common/btn-polygon.png');

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}
