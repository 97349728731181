@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// HubSpotのカスタムフォームに対してのcss適用

.hbspt-form {
  max-width: 450px;
  margin-left: auto;

  @include max-screen($breackpoint_md) {
    max-width: 100%;
    margin-inline: auto;
  }

  input[type='checkbox'] {
    position: relative;
    padding-left: 34px; //チェックボックスとテキストの余白
    vertical-align: middle;
    cursor: pointer; /*label手前にチェックボックス用の余白を開ける*/

    @include max-screen($breackpoint_md) {
      padding-left: pxtovw(34); //チェックボックスとテキストの余白
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 50%;
      left: 5px;
      width: 17px;
      height: 17px;
      background-color: #fff;
      border: 1px solid #000000;
      border-radius: 0%;
      border-radius: 5px;
      transform: translateY(-50%);

      @include max-screen($breackpoint_md) {
        width: pxtovw(15);
        height: pxtovw(15);
      }
    }

    &::after {
      top: -12px;
      left: 5px;
      width: 20px;
      height: 12px;
      border-bottom: 2px solid #666464;
      border-left: 2px solid #666464;
      opacity: 0;
      transform: rotate(-45deg);

      @include max-screen($breackpoint_md) {
        top: pxtovw(-12);
        width: pxtovw(20);
        height: pxtovw(12);
      }
    }

    &:checked::after {
      opacity: 1; /*チェック後表示*/
    }
  }

  // input[type='checkbox']::before,
  // input[type='checkbox']::after {
  //   position: absolute;
  //   display: block;
  //   content: '';
  // }

  // input[type='checkbox']::before {
  //   top: 50%;
  //   left: 5px;
  //   width: 17px;
  //   height: 17px;
  //   background-color: #fff;
  //   border: 1px solid #000000;
  //   border-radius: 0%;
  //   border-radius: 5px;
  //   transform: translateY(-50%); /*チェックボックスの横幅*/ /*チェックボックスの縦幅*/
  // }

  // input[type='checkbox']::after {
  //   top: -12px;
  //   left: 5px;
  //   width: 20px;
  //   height: 12px;
  //   border-bottom: 2px solid #666464;
  //   border-left: 2px solid #666464;
  //   opacity: 0;
  //   transform: rotate(-45deg); /*チェックの太さ*/ /*チェックの太さ*/ /*チェック前は非表示*/ /*チェックの高さ*/ /*チェックの横幅*/ /*チェック時の位置調整*/ /*チェック時の位置調整*/
  // }

  // input[type='checkbox']:checked::after {
  //   opacity: 1; /*チェック後表示*/
  // }
}

.input {
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  input,
  textarea {
    width: 100%;
    height: 100%;
    &:focus {
      border: none;
      outline-color: #eb0517;
    }
  }
}

.hs_company,
.hs_lastname,
.hs_email,
.hs_phone {
  max-width: 450px;
  height: 55px;
  margin-bottom: 19px;

  @include max-screen($breackpoint_md) {
    max-width: 100%;
    height: pxtovw(52);
  }

  input,
  textarea {
    padding: 20px 40px 19px 15px;
    font-size: 14px;
    border: 1px solid #1e1e1e;
    border-radius: 16px;

    @include max-screen($breackpoint_md) {
      padding: pxtovw(20) pxtovw(40) pxtovw(19) pxtovw(15);
      // padding: 20px 15px;
      font-size: pxtovw(12);
      // font-size: 16px;
    }
  }
}

.hs_company {
}

.hs_lastname {
}

.hs_email {
}

.hs_phone {
}

.hs_message {
  max-width: 450px;
  height: 55px;
  margin-bottom: 24px;

  @include max-screen($breackpoint_md) {
    max-width: 100%;
    height: pxtovw(52);
    margin-bottom: pxtovw(28);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  textarea {
    padding: 16px 40px 16px 15px;
    font-size: 14px;
    border: 1px solid #1e1e1e;
    border-radius: 16px;
    scrollbar-width: none;

    @include max-screen($breackpoint_md) {
      padding: pxtovw(16) pxtovw(40) pxtovw(16) pxtovw(15);
      font-size: pxtovw(12);
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hs_attribute {
  margin-bottom: 10px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(10);
  }
  input {
    width: auto;
  }
}

// エラー文章
.hs-error-msgs {
  font-size: 12px;
  color: #eb0717;

  .hs-error-msg {
    padding-left: 1em;
  }
}

.hs-form-checkbox {
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }

  &:first-of-type {
    font-family: $f--gothic-stdn;
    color: $c--accent;

    input[type='checkbox']::before {
      border: 1px solid $c--accent;
    }

    input[type='checkbox']::after {
      border-bottom: 2px solid $c--accent;
      border-left: 2px solid $c--accent;
    }
  }
}

.hs_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238px;
  height: 84px;
  font-family: $f--gothic-stdn;
  font-size: 15px;
  text-align: center;
  border-radius: 84px;
  box-shadow: 0 3px 6px $c--accent-btn;
  transition: all 0.2s ease;

  @include max-screen($breackpoint_md) {
    width: 50%;
    min-width: 160px;
    height: pxtovw(48);
    font-size: pxtovw(12);
  }

  &:hover {
    box-shadow: 0 10px 10px $c--accent-btn;
    transition: all 0.2s ease;
    transform: translateY(-7px);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  input[type='submit'] {
    width: 100%;
    height: 100%;
  }
}

// プライバシーポリシー
.legal-consent-container {
  margin-bottom: 30px;
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(35);
    font-size: pxtovw(12);
  }

  .hs-form-booleancheckbox-display {
    > span {
      margin-left: 34px !important; //チェックボックスとテキストの余白
      @include max-screen($breackpoint_md) {
        margin-left: pxtovw(34) !important; //チェックボックスとテキストの余白
      }
    }
    input[type='checkbox'] {
      &::before {
        top: 13px;
        @include max-screen($breackpoint_md) {
          top: pxtovw(10) !important; //チェックボックスとテキストの余白
        }
      }

      &::after {
        top: 0;
      }

      &:checked::after {
        @include max-screen($breackpoint_md) {
          top: pxtovw(-5) !important; //チェックボックスとテキストの余白
        }
      }
    }
  }
}
