@charset 'utf-8';

@use '../foundation/global/_global' as *;

// コンテンツ幅

.l-container {
  max-width: $container;
  padding-right: 80px;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;
  @include max-screen($breackpoint_md) {
    padding-right: pxtovw(20);
    padding-left: pxtovw(20);
  }
}
