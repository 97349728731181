@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section09 {
  position: relative;
  padding-top: 75px;
  padding-bottom: 100px;
  margin-bottom: 123px;
  background: #fcf4e7;

  @include max-screen($breackpoint_md) {
    padding-top: pxtovw(50);
    padding-bottom: pxtovw(75);
  }

  &::before {
    position: absolute;
    inset: 0;
    width: pxtovw(114);
    height: pxtovw(122);
    content: '';
    background-size: cover;
    @include background-image('index/section/sp/09_bg-image.png');

    @include min-screen(767px) {
      display: none;
    }
  }
}
.p-section09__title {
  height: 50px;
  margin-bottom: 40px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(30);
    margin-bottom: pxtovw(50);
  }
}
.p-section09__meta {
  display: flex;
  align-items: flex-start;
  margin-bottom: 74px;

  @include max-screen($breackpoint_md) {
    flex-direction: column;
    align-items: center;
    margin-bottom: pxtovw(50);
    font-size: pxtovw(30);
  }

  img {
    width: 418px;

    @include max-screen($breackpoint_md) {
      width: 100%;
      max-width: pxtovw(163);
      margin-bottom: pxtovw(31);
    }
  }
}
.p-section09__company {
  padding-top: 30px;

  @include max-screen($breackpoint_md) {
    padding-top: 0;
    font-size: pxtovw(12);
  }

  li {
    font-size: 14px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }

    &:first-of-type {
      margin-bottom: 8px;
      font-family: $f--gothic-stdn;
      font-size: 18px;
      font-weight: 900;

      @include max-screen($breackpoint_md) {
        margin-bottom: 1em;
        font-size: pxtovw(15);
      }
    }
  }
}
.p-section09__directors {
  display: flex;

  @include max-screen(1024px) {
    flex-direction: column;
  }

  &:first-of-type {
    padding-right: 0;
    padding-left: 44px;
    border-right: 1.5px solid dashed;
  }
}
.p-section09__director {
  width: 50%;

  @include max-screen(1024px) {
    width: 100%;
  }

  &:first-of-type {
    padding-right: 44px;
    border-right: 1px dashed $c--black;

    @include max-screen(1024px) {
      padding-right: 0;
      margin-bottom: 50px;
      border-right: none;
      border-bottom: 1px dashed $c--black;
    }

    @include max-screen($breackpoint_md) {
      padding-bottom: pxtovw(50);
      margin-bottom: pxtovw(50);
    }
  }

  &:last-of-type {
    padding-left: 44px;

    @include max-screen(1024px) {
      padding-left: 0;
    }

    img {
      @include max-screen($breackpoint_md) {
        order: 1;
      }
    }

    .p-section09__info {
      @include max-screen($breackpoint_md) {
        text-align: left;
      }
    }

    .p-section09__message {
      font-size: 18px;
      @include max-screen($breackpoint_md) {
        font-size: pxtovw(15);
      }
    }
  }
}

.p-section09__director-head {
  display: flex;
  gap: 53px;
  margin-bottom: 23px;

  @include max-screen($breackpoint_md) {
    gap: 0;
    align-items: center;
    justify-content: space-between;
  }

  img {
    max-width: 182px;

    @include max-screen($breackpoint_md) {
      max-width: pxtovw(182);
    }
  }
}

.p-section09__info {
  margin-top: 30px;

  @include max-screen($breackpoint_md) {
    text-align: right;
  }
}

.p-section09__role {
  padding: 6px 13px;
  margin-bottom: 12px;
  font-size: 12px;
  border: 1px solid #1e1e1e;

  @include max-screen($breackpoint_md) {
    padding: pxtovw(6) pxtovw(10);
    font-size: pxtovw(10);
  }
}
.p-section09__name {
  font-family: $f--gothic-stdn;
  font-size: 20px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(20);
  }
}
.p-section09__ruby {
  font-size: 12px;
  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
}
.p-section09__text {
  p {
    margin-bottom: 16px;
  }
  @include max-screen($breackpoint_md) {
    padding-right: pxtovw(16);
    padding-left: pxtovw(16);
  }
}
.p-section09__achievement {
  font-family: $f--gothic-stdn;
  font-size: 14px;
  color: $c--accent;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
}
.p-section09__message {
  font-family: $f--gothic-stdn;
  font-size: 20px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(15);
  }
}
.p-section09__career {
  margin-bottom: 0 !important;
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
}
