@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-ribbon {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    @include background-image('_common/ribbon.png');
    background-size: cover;
  }
}
