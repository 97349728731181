@charset 'utf-8';

@use '../foundation/global/_global' as *;

// コンテンツ幅

.l-download {
  max-width: $container;
  padding-right: 80px;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;
  @include max-screen($breackpoint_md) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @include max-screen($breackpoint_sm) {
    padding-right: 20px;
    padding-left: 20px;
  }
}
