@charset 'utf-8';

@use '../../foundation/global/_global' as *;

.p-index {
  margin-bottom: 125px;
  color: $c--black;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(71);
  }
}
.p-index__title {
  margin-inline: auto;
  margin-bottom: 8px;
  font-family: p22-underground, sans-serif;
  font-size: 35px;
  color: $c--accent;
  text-align: center;
  font-weight: 600;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(15);
    font-size: pxtovw(25);
  }
}
.p-index__menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888;

  @include max-screen($breackpoint_lg) {
    flex-direction: column;
  }
}
.p-index__list {
}

.p-index__list-item {
  padding-right: 8px;
  border-bottom: 1px dashed #888888;

  @include max-screen($breackpoint_md) {
    padding-right: 0;
  }

  &[data-num='06'] {
    border-bottom: none;

    @include max-screen($breackpoint_lg) {
      border-bottom: 1px dashed #888888;
    }
  }

  &[data-num='07'] {
    ul {
      min-width: 265px;
    }
  }

  &[data-num='11'] {
    @include max-screen($breackpoint_md) {
      border-bottom: none;
    }
  }
}
.p-index__list-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 421px;
  padding-top: 10px;
  padding-bottom: 10px;
  // gap: 0px 25px;
  white-space: nowrap;

  @include max-screen($breackpoint_lg) {
    max-width: 100%;
  }

  @include max-screen($breackpoint_md) {
    flex-wrap: nowrap;
    gap: pxtovw(17);
    padding-top: pxtovw(15);
    padding-bottom: pxtovw(15);
  }

  // &::before,
  // &::after {
  //   top: 30px;
  //   right: 0;
  //   content: '';
  // }

  // &::before {
  //   width: 20px;
  //   height: 2px;
  // }

  // &::after {
  //   right: -2px;
  //   width: 10px;
  //   height: 10px;
  // }

  // &:hover {
  //   &::before {
  //     right: 4px;
  //     width: 10px;
  //   }

  //   &::after {
  //     right: 0;
  //   }
  // }
}
.p-index__list-link--sub {
  // padding-right: 29px;
}

.p-index__list-num {
  width: 30px;
  font-family: p22-underground, sans-serif;
  font-size: 25px;
  line-height: 1.3;

  @include max-screen($breackpoint_md) {
    width: pxtovw(25);
    font-size: pxtovw(20);
  }
}
.p-index__list-text {
  width: calc(100% - 30px);
  padding-left: 33px;
  font-family: $f--gothic-stdn;
  font-size: 18px;

  span {
    display: block;
    font-family: $f--gothic-pr6n;
    font-size: 14px;
  }

  b {
    position: relative;
    display: block;
    &::before,
    &::after {
      top: 15px;
      right: 0;
      width: 20px;
      height: 2px;
      content: '';
      transition: width 0.3s, right 0.3s;
      @include max-screen($breackpoint_md) {
        top: auto;
        bottom: 15px;
      }
    }
    &::before {
      @at-root .p-index__list-link:hover & {
        right: 7px;
        width: 13px;
      }
    }
    &::after {
      right: -3px;
      width: 10px;
      height: 10px;
      margin-top: -0.5em;
      font-size: 1px;
      @include max-screen($breackpoint_md) {
        right: 1px;
        bottom: 11px;
        transform: rotate(-45deg);
      }
      @at-root .p-index__list-link:hover & {
        right: 4px;
      }
    }
  }

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(15);

    span {
      font-size: pxtovw(12);
    }
  }
}
.p-index__sub-list {
  position: relative;
  margin-left: auto;

  @include max-screen($breackpoint_md) {
    display: none;
  }
}
.p-index__sub-list-item {
  font-size: 14px;
}
.p-index__sub-list-item--reason {
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    left: -56px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    background-color: #888888;
    border-radius: 59px;
    transform: translateY(-50%);
  }
  &:first-of-type {
    &::before {
      content: '理由1';
    }
  }
  &:nth-of-type(2) {
    &::before {
      content: '理由2';
    }
  }
  &:last-of-type {
    &::before {
      content: '理由3';
    }
  }
}
.p-index__sub-list-item--question {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #888888;

  &:first-of-type {
    padding-top: 0;

    &::before {
      position: absolute;
      left: -32px;
      font-size: 22px;
      font-weight: 900;
      line-height: 1;
      content: 'Q';
    }
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}
