@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section06 {
}
.p-section06__title {
  height: 50px;
  margin-bottom: 50px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(30);
  }
}
.p-section06__splide {
  margin-bottom: 186px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(110);
  }
}
.p-section06__track {
  max-width: 100% !important;
  padding: 10px 10px 13px;
  @include max-screen($breackpoint_md) {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
.p-section06__list {
}
.p-section06__slide {
  width: 845px + 50px !important;
  padding-left: 50px;
  @include max-screen($breackpoint_md) {
    width: pxtovw(269) !important;
    padding: 0 !important;
  }
  &:last-of-type {
    width: 845px + 100px;
    padding-right: 50px;
  }
}

.p-section06__slide-card {
  padding: 50px 35px;
  font-size: 14px;
  border-radius: 15px;
  box-shadow: 1px 3px 10px rgba(#888888, 0.5);
  @include max-screen($breackpoint_md) {
    display: flex;
    flex-direction: column;
    padding: pxtovw(35) pxtovw(13);
    font-size: pxtovw(12);
  }
  img {
    max-width: 590px;
    border: 1px solid #d9d9d9;
  }
}

.p-section06__slide-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px dashed #888888;

  @include max-screen($breackpoint_md) {
    padding-bottom: pxtovw(13);
    padding-bottom: pxtovw(15);
    font-size: pxtovw(12);
    letter-spacing: -0.02em;
  }
}

.p-section06__slide-content {
  display: flex;
  justify-content: space-between;

  @include max-screen($breackpoint_md) {
    flex-direction: column;
    margin-bottom: pxtovw(35);
  }
}

.p-section06__slide-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;

    @include max-screen($breackpoint_md) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: pxtovw(5);
      min-height: pxtovw(59);
      margin-bottom: pxtovw(25);
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 13px;
    font-size: 10px;
    background-color: #d9d9d9;
    border-radius: 28px;
    @include max-screen($breackpoint_md) {
      padding: pxtovw(5) pxtovw(13);
      font-size: pxtovw(10);
    }
  }
}

.p-section06__pagination {
  bottom: -50px !important;

  @include max-screen($breackpoint_md) {
    bottom: pxtovw(-25);
  }
}

.p-section06__page {
  width: 11px;
  height: 11px;
  margin-inline: 8px !important;

  &.is-active {
    background: #333232;
    transform: scale(1) !important;
  }
}

.p-section06__button {
  width: 100px;
  padding-bottom: 10px;
  border-bottom: 1px solid #707070;

  @include max-screen($breackpoint_md) {
    margin-inline: auto;
  }

  &::before,
  &::after {
    top: 11px;
    right: 0;
    width: 20px;
    height: 2px;
    content: '';
    transition: width 0.3s, right 0.3s;
    @include max-screen($breackpoint_md) {
      top: auto;
      bottom: 20px;
    }
  }
  &::before {
    @at-root .p-section06__button:hover::before {
      right: 7px;
      width: 13px;
    }
  }
  &::after {
    right: -3px;
    width: 10px;
    height: 10px;
    margin-top: -0.5em;
    font-size: 1px;
    @include max-screen($breackpoint_md) {
      right: 1px;
      bottom: 16px;
      transform: rotate(-45deg);
    }
    @at-root .p-section06__button:hover::after {
      right: 4px;
    }
  }
}
