@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section07 {
  padding-top: 92px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  background: #f0f0f0;

  @include max-screen($breackpoint_md) {
    padding-top: pxtovw(50);
    margin-bottom: pxtovw(50);
  }
}
.p-section07__title {
  height: 50px;
  margin-bottom: 87px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(30);
  }
}
.p-section07__question {
  position: relative;
  padding: 25px 46px;
  margin-bottom: 102px;
  font-family: $f--gothic-stdn;
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(1px 3px 10px rgba(#888888, 0.5));
  border-radius: 15px;

  // firefox
  @-moz-document url-prefix() {
    &::before {
      top: -58px !important;

      @include max-screen($breackpoint_md) {
        top: -35px !important;
      }
    }
  }

  @include max-screen($breackpoint_md) {
    padding: pxtovw(35) pxtovw(16);
    margin-bottom: pxtovw(35);
    font-size: pxtovw(20);
  }

  &::before {
    position: absolute;
    top: -73px;
    left: 50%;
    font-family: josefin-sans, sans-serif;
    font-size: 85px;
    font-weight: normal;
    line-height: 1;
    color: $c--accent;
    content: 'Q';
    transform: translateX(-50%);

    @include max-screen($breackpoint_md) {
      top: -40px;
      font-size: 55px;
    }
  }

  &::after {
    position: absolute;
    bottom: -23px;
    left: 50%;
    width: 38px;
    height: 24px;
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    font-family: josefin-sans, sans-serif;
    content: '';
    background-color: #fff;
    box-shadow: 1px 3px 10px rgba(#888888, 0.5);
    transform: translateX(-50%);

    @include max-screen($breackpoint_md) {
      bottom: -15px;
      width: 26px;
      height: 16px;
    }
  }
}

.p-section07__sub-title {
  margin-bottom: 57px;

  @include max-screen($breackpoint_md) {
    display: none;
    width: 100vw;
    margin-inline: calc(50% - 50vw);
    margin-bottom: pxtovw(40);
  }
}
.p-section07__card {
  position: relative;
  width: 33.3%;
  box-shadow: none;

  &[data='answer'] {
    border-radius: 0;
  }

  @include max-screen($breackpoint_md) {
    width: 100%;
  }

  img {
    margin-bottom: 25px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(32);
    }
  }
}

.p-section07__conpany-detail {
  position: relative;
  display: block;
  min-height: 103px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px dashed $c--accent;
  @include max-screen($breackpoint_md) {
    min-height: auto;
    padding-bottom: pxtovw(20);
    margin-bottom: pxtovw(20);
  }
  .p-section07__card-title {
    font-family: $f--gothic-stdn;
    font-size: 18px;
    // border-bottom: 1px dashed $c--accent;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  p {
    font-size: 14px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }
}

.p-section07__card-text {
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }

  ul {
    margin-bottom: 12px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(24);
    }
  }
  dl {
    margin-bottom: 25px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(25);
    }
  }

  dt {
    margin-bottom: 10px;
    font-family: $f--gothic-stdn;
    font-size: 15px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(20);
      font-size: pxtovw(14);
    }
  }
  dd {
    margin-bottom: 14px;
    font-size: 14px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(25);
      font-size: pxtovw(12);
    }
  }
}
.p-section07__card-link {
}
.p-section07__cards-wrap {
  flex-wrap: nowrap;
  gap: 38px;
  margin-bottom: 100px;

  @include max-screen($breackpoint_md) {
    gap: pxtovw(75);
    margin-bottom: pxtovw(75);
  }
}
.p-section07-cards__card {
  width: 33.3%;

  &[data='answer'] {
    border-radius: 0;
    box-shadow: none;
  }
}

.p-section07__answer-text {
  position: relative;
  min-height: 231px;
  padding: 43px 25px 21px 25px;
  margin-bottom: 20px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 1px 3px 10px rgba(#888888, 0.5);

  @include max-screen($breackpoint_md) {
    min-height: 220px;
    padding: 35px 16px 25px;
    font-size: 12px;
  }

  // firefox
  @-moz-document url-prefix() {
    &::before {
      top: -42.5px !important;

      @include max-screen($breackpoint_md) {
        top: -27.5px !important;
      }
    }
  }

  &::before {
    position: absolute;
    top: -59px;
    left: 50%;
    font-family: josefin-sans, sans-serif;
    font-size: 85px;
    font-weight: 300;
    line-height: 1;
    color: $c--accent;
    content: 'A';
    transform: translateX(-50%);
    @include max-screen($breackpoint_md) {
      top: -38px;
      font-size: 55px;
    }
  }
}

.p-section07__answer-info {
}
.p-section07__company {
  margin-bottom: 12px;
  font-size: 14px;
  border-bottom: 1px dashed #888888;
  @include max-screen($breackpoint_md) {
    font-size: 12px;
  }
}

.p-section07__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.p-section07__tag {
  padding: 8px 13px;
  font-size: 10px;
  background-color: #d9d9d9;
  border-radius: 28px;
}

.p-section07__splide {
  margin-bottom: pxtovw(75);
  @include max-screen($breackpoint_md) {
    width: 100vw;
    height: 346px;
    margin: 0 calc(50% - 50vw) pxtovw(75);
    overflow: hidden;
  }
}
.p-section07__track {
  @include max-screen($breackpoint_md) {
    padding: 0;
    margin: 0;
  }
}
.p-section07__list {
  @include max-screen($breackpoint_md) {
    display: flex !important;
    flex-wrap: nowrap;
    height: 366px;
    overflow-x: auto;
    > * {
      position: relative;
      margin-left: 20px;
      &:last-of-type {
        &::after {
          position: absolute;
          top: 0;
          left: 100%;
          width: 20px;
          height: 1px;
          content: '';
          background-color: transparent;
        }
      }
    }
  }
}
.p-section07__slide {
  max-width: 217px;
  padding-top: 35px; //「A」の疑似要素が隠れてしまうため。
}

.p-section07__card-link-wrap {
  position: absolute;
  right: 0;
}

.p-section07__card-link {
  width: 100px;
  padding-bottom: 10px;
  font-size: 14px;
  border-bottom: 1px solid #707070;
  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
  &::before,
  &::after {
    top: 11px;
    right: 0;
    width: 20px;
    height: 2px;
    content: '';
    transition: width 0.3s, right 0.3s;
    @include max-screen($breackpoint_md) {
      top: auto;
      bottom: 20px;
    }
  }
  &::before {
    @at-root .p-section07__card-link:hover::before {
      right: 7px;
      width: 13px;
    }
  }
  &::after {
    right: -3px;
    width: 10px;
    height: 10px;
    margin-top: -0.5em;
    font-size: 1px;
    @include max-screen($breackpoint_md) {
      right: 1px;
      bottom: 16px;
      transform: rotate(-45deg);
    }
    @at-root .p-section07__card-link:hover::after {
      right: 4px;
    }
  }
}
