@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $f--gothic-stdn;
  font-weight: bold;
  text-align: center;
  background-color: $c--white;
  border-radius: 84px;
  box-shadow: 0 3px 6px $c--accent-btn;
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    box-shadow: 0 10px 10px rgba($c--accent-btn, 0.6);
    transform: translateY(-3px);
  }
}

.c-button__text-wrap {
}

.c-button__text {
  display: block;
}

.c-button__arrow {
  position: relative;

  &::before {
    position: absolute;
    left: 50%;
    content: '';
    background-size: cover;
    transform: translateX(-50%);
    @include background-image('_common/btn-polygon.png');
  }

  &[data='white-icon'] {
    &::before {
      @include background-image('_common/btn-polygon-white.png');
    }
  }
}

.c-button__text--red {
  color: $c--accent;
}
