@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section03 {
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 100px;
  overflow-y: hidden;
  overflow-y: visible;
  background-color: #fcf4e7;
  background-position: center;
  background-size: contain;
  background-size: min(100%, 1366px);
  @include background-image('index/section/03_bg-image.png');

  @include max-screen($breackpoint_md) {
    @include background-image('index/section/sp/03_bg-image.png');
    padding-bottom: pxtovw(75);
    margin-bottom: pxtovw(50);
  }

  .l-inner {
    position: relative;
  }
}

.bg {
  position: absolute;
  inset: -182px 0 0 0;
  width: 100%;
  max-width: 1366px;
  margin-inline: auto;

  @include max-screen($breackpoint_md) {
    inset: pxtovw(47) 0 0 0;
  }

  img {
    width: 50%;

    @include max-screen($breackpoint_md) {
      width: 71%;
    }
  }
}

.p-section03__bg-image {
  position: absolute;
  inset: 0;
  z-index: -100;
}
.p-section03__sub-section {
  &[data='reason01'] {
    margin-bottom: 100px;
    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(50);
    }

    .p-section03__content {
      margin-bottom: 50px;

      @include max-screen($breackpoint_md) {
        margin-bottom: pxtovw(57);
      }
    }
  }

  &[data='reason02'] {
    margin-bottom: 90px;

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(50);
    }
  }

  &[data='reason03'] {
    .p-section03__content {
      margin-bottom: 25px;

      @include max-screen($breackpoint_md) {
        margin-bottom: pxtovw(30);
      }
    }
  }
}
.p-section03__heading {
  width: 100%; //100vwだとスクロールバー分水平スクロールが表示されてしまうので
  margin-inline: calc(50% - 50vw);
  margin-bottom: 88px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(50);
  }
}
.p-section03__title {
  max-width: 450px;
  margin-inline: auto;
  margin-bottom: 50px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    width: 100%;
    margin-bottom: pxtovw(39);
  }
}

.p-section03__content {
  width: calc(100% + pxtovw(20));
  margin-left: pxtovw(-10);
}

// .p-section03__content--grid {
//   display: grid;
//   grid-template-areas:
//     'card01 card01 card02 card02'
//     'card01 card01 card02 card02'
//     'card03 card04 card05 card06'
//     'card03 card04 card05 card06';
//   grid-template-rows: 1fr 1fr 1fr 1fr;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   grid-auto-flow: row;
//   gap: 20px;
//   box-shadow: none;

//   @include max-screen($breackpoint_md) {
//     grid-template-areas:
//       'card01 card01 card01 card01'
//       'card02 card02 card02 card02'
//       'card03 card03 card04 card04'
//       'card05 card05 card06 card06';
//     font-size: pxtovw(12);
//   }
// }

.p-section03__item {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 1px 3px 10px rgba(#888888, 0.5);

  &[data-card='card01'] {
    grid-area: card01;
  }

  &[data-card='card02'] {
    grid-area: card02;
  }

  &[data-card='card03'] {
    grid-area: card03;
  }

  &[data-card='card04'] {
    grid-area: card04;
  }

  &[data-card='card05'] {
    grid-area: card05;
  }

  &[data-card='card06'] {
    grid-area: card06;
  }
}

.p-section03__caption {
  font-size: 14px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
}
