@charset 'utf-8';

@use 'global/_global' as *;

body {
  font-family: $f--gothic-pr6n;
  font-size: 16px;
  line-height: 1.7;
  color: $c--text;
  word-break: break-all;

  &.is-modal {
    /* 追加 */
    overflow: hidden;
  }
}

h2 {
  img {
    height: 100%;
    object-fit: contain;
  }
}
