@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-horizontal {
  margin-bottom: 56px;

  @include max-screen($breackpoint_md) {
    width: 100vw;
    margin-inline: calc(50% - 50vw);
    margin-bottom: pxtovw(35);
    padding-left: 0;
    padding-right: 0;
  }
}
