@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// 要素非表示
.u-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
}

// width
// @for $i from 0 through 20 {
//   .u-w#{$i * 20} {
//     width: #{$i * 20}px !important;
//   }
// }

.u-red {
  color: $c--accent;
}

.u-bold {
  font-weight: bold;
}

.u-text-left {
  text-align: left !important;
}

.u-pr-1em {
  padding-right: 1em;
}

.u-mt50 {
  margin-top: 50px;
}
.u-md-mt30 {
  @include max-screen($breackpoint_md) {
    margin-top: 30px;
  }
}

// 769px以上非表示
.u-pc-inactive {
  @include min-screen(769px) {
    display: none;
  }
}

// 768px以下非表示
.u-sm-inactive {
  @include max-screen($breackpoint_md) {
    display: none;
  }
}
