@charset 'utf-8';

@use '../foundation/global/_global' as *;

// コンテンツ幅

.l-contact {
  padding-top: 50px;
  margin-bottom: 75px;
}
.l-contact__heading {
  margin-bottom: 48px;
}
.l-contact__contents {
  display: flex;
  justify-content: space-between;

  @include max-screen($breackpoint_md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
}
.l-contact__sm-title {
  display: none;
  @include max-screen($breackpoint_md) {
    display: block;
    margin-bottom: 50px;
    font-family: $f--gothic-stdn;
    font-size: 30px;
  }
}
.l-contact__sm-logo {
  display: none;
  @include max-screen($breackpoint_md) {
    display: block;
    width: 150px;
    margin-bottom: 20px;
  }
}
.l-contact__title {
  font-family: $f--gothic-stdn;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  @include max-screen($breackpoint_md) {
    font-size: 15px;
    text-align: left;
  }
}
.l-contact__desc {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  @include max-screen($breackpoint_md) {
    font-size: 12px;
    text-align: left;
  }
}
.l-contact__example-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-top: 20px;
  @include max-screen($breackpoint_md) {
    margin-top: 15px;
  }
  a {
    display: inline-block;
    padding: 11px 24px;
    font-size: 14px;
    background-color: #f0f0f0;
    border-radius: 100vmax;
    transition: opacity 0.2s;
    @include max-screen($breackpoint_md) {
      padding: 4px 12px;
      font-size: 12px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
.l-contact__kv-img {
  max-width: 497px;
  margin: 50px auto;
}
.l-contact__info {
  width: 50%;

  @include max-screen($breackpoint_md) {
    width: 100%;
    margin-bottom: pxtovw(30);
  }
}
.l-contact__form {
  width: 50%;

  @include max-screen($breackpoint_md) {
    width: 100%;
  }

  iframe {
    @include max-screen($breackpoint_md) {
      max-width: pxtovw(360);
    }
  }
}

.l-contact--confirm {
  .c-button {
    width: calc((238 / 1200) * 100vw);
    max-width: 238px;
    height: calc((84 / 1200) * 100vw);
    max-height: 84px;
    font-size: 15px;
    font-weight: 900;

    @include max-screen($breackpoint_md) {
      width: 50%;
      max-width: 100%;
      height: pxtovw(48);
      font-size: pxtovw(12);
    }
  }

  .l-contact__contents {
    @include max-screen($breackpoint_lg) {
      flex-direction: column;
      align-items: center;
    }

    img {
      margin-bottom: 50px;
    }
  }

  .l-contact__info {
    @include max-screen($breackpoint_lg) {
      width: 100%;
    }

    @include max-screen($breackpoint_md) {
      margin-bottom: pxtovw(50);
    }
  }

  .l-contact__form {
    max-width: 370px;

    @include max-screen($breackpoint_lg) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.l-contact--thanks {
  .l-contact__heading {
    margin-bottom: 80px;
  }

  .l-contact__contents {
    margin-bottom: 50px;
  }

  .l-contact__button-wrap {
    width: 100%;
  }

  .l-contact__button {
    display: block !important;
    width: 117px;
    padding-bottom: 10px;
    margin-inline: auto;
    font-size: 14px;
    border-bottom: 1px solid #707070;

    @include max-screen($breackpoint_lg) {
      width: pxtovw(104);
      min-width: 104px;
      font-size: pxtovw(12);
    }

    &::before,
    &::after {
      top: 10px;
      right: 0;
      content: '';
    }

    &::before {
      width: 20px;
      height: 2px;
    }

    &::after {
      right: -2px;
      width: 10px;
      height: 10px;
    }

    &:hover {
      &::before {
        right: 4px;
        width: 10px;
      }

      &::after {
        right: 0;
      }
    }
  }
}

// l-contact--dl
.l-contact--dl {
  .l-contact__info {
    width: 55%;
    @include max-screen($breackpoint_lg) {
      width: 100%;
    }
  }
  .l-contact__form {
    width: 45%;
    @include max-screen($breackpoint_lg) {
      width: 100%;
    }
  }
}

.l-contact--dl-confirm {
  .c-button {
    margin-bottom: 75px;

    @include max-screen($breackpoint_lg) {
      margin-bottom: pxtovw(59);
    }
  }
}

.l-contact-grid {
  display: grid;
  grid-template-areas: 'title title' 'logo step' 'meta form';
  grid-template-columns: 50% 50%;
  align-items: flex-start;
  @include max-screen($breackpoint_md) {
    grid-template-areas: 'title' 'logo' 'meta' 'form';
    grid-template-columns: 100%;
  }
}
.l-contact-grid__title {
  grid-area: title;
  font-family: $f--gothic-stdn;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  @include max-screen($breackpoint_md) {
    font-size: 30px;
    text-align: left;
  }
}
.l-contact-grid__step {
  display: flex;
  grid-area: step;
  justify-content: flex-end;
  img {
    width: 280px;
  }
}
.l-contact-grid__logo {
  grid-area: logo;
  @include max-screen($breackpoint_md) {
    padding-top: 40px;
  }
  img {
    width: 198px;
    @include max-screen($breackpoint_md) {
      width: 150px;
    }
  }
}
.l-contact-grid__meta {
  grid-area: meta;
  padding-top: 50px;
  @include max-screen($breackpoint_md) {
    padding-top: 20px;
  }
}
.l-contact-grid__form {
  grid-area: form;
  padding-top: 50px;
  padding-left: 80px;
  @include max-screen($breackpoint_md) {
    padding-top: 30px;
    padding-left: 0;
  }
  form {
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    @include max-screen($breackpoint_md) {
      max-width: 100%;
    }
  }
}
