@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section04 {
  margin-bottom: 125px;
  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(100);
  }
}
.p-section04__inner {
  max-width: 1200px !important;
}
.p-section04__title {
  height: 50px;
  margin-bottom: 50px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(30);
    margin-bottom: pxtovw(35);
  }

  &:nth-of-type(2) {
    @include max-screen($breackpoint_md) {
      height: pxtovw(70);
    }
  }
}
.p-section04__contents {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 15px;
  margin-bottom: 100px;

  @include max-screen($breackpoint_md) {
    gap: pxtovw(10) pxtovw(5);
    margin-bottom: pxtovw(50);
  }
}
.p-section04__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #1e1e1e;
  border-radius: 28px;

  @include max-screen($breackpoint_md) {
    padding: pxtovw(4) pxtovw(11);
    font-size: pxtovw(10);
  }
}
.p-section04__content--gray {
  background-color: #f0f0f0;
  border: none;
}
.p-section04__button-wrap {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-inline: auto;

  @include max-screen($breackpoint_lg) {
    flex-direction: column;
    align-items: center;
  }

  @include max-screen($breackpoint_md) {
    gap: pxtovw(20);
  }
}
.p-section04__button {
  width: 100%;
  max-width: 425px;
  height: 168px;
  @include max-screen($breackpoint_md) {
    max-width: pxtovw(330);
    height: pxtovw(100);
  }

  .c-button__text {
    font-size: 30px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  .c-button__text--red {
    font-size: 18px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;
      @include background-image('_common/btn-polygon.png');

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}
