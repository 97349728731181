@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section01 {
  margin-bottom: 36px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(50);
  }
}
.p-section01__title {
  margin-bottom: 60px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(35);
  }
}
.p-section01-cards {
  margin-bottom: 2px;
}
.p-section01-cards__wrap {
  flex-wrap: nowrap;
  gap: 38px;
  margin-bottom: 25px;

  @include max-screen($breackpoint_md) {
    gap: pxtovw(20);
    width: 100%;
    margin-bottom: pxtovw(30);
  }
}
.p-section01-cards__card {
  width: 33.3%;

  @include max-screen($breackpoint_md) {
    width: 100%;
  }
}
.p-section01-cards__caption {
  font-size: 14px;
  text-align-last: left;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }
}
