@charset 'utf-8';

@use '../foundation/global/_global' as *;

// コンテンツ幅

.l-inner {
  max-width: $inner;
  padding-right: 80px;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;

  @include max-screen($breackpoint_md) {
    padding-right: pxtovw(16);
    padding-left: pxtovw(16);
  }
}
