@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-bland {
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;
  &.is-show {
    visibility: visible;
    opacity: 1;
  }
}
.p-bland__overlay {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(#333232, 0.8);
}
.p-bland__inner {
  width: 100%;
  max-width: 1230px;
  height: calc(100vh - 75px);
  padding-right: 20px;
  padding-left: 20px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include max-screen(1250px) {
    width: 90%;
    max-width: 100%;
  }
  @include max-screen($breackpoint_md) {
    width: 100%;
    height: calc(100% - 40px);
    // padding-right: pxtovw_md(16);
    // padding-left: pxtovw_md(16);
    padding-right: 0;
    padding-left: 0;
  }
}
.p-bland__inner-overflow-hidden {
  @include max-screen($breackpoint_md) {
    padding-right: 20px;
    padding-left: 20px;
    overflow: hidden;
  }
}

.p-bland__title {
  padding-top: 129px;
  margin-bottom: 50px;
  font-family: $f--gothic-stdn;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @include max-screen($breackpoint_md) {
    width: calc(100% + 40px);
    padding-top: pxtovw_md(129);
    margin-bottom: pxtovw_md(50);
    margin-left: -20px;
    font-size: pxtovw_md(32);
  }

  span {
    position: relative;
    font-size: 42px;
    @include max-screen($breackpoint_md) {
      font-size: pxtovw_md(42);
    }

    &::before {
      position: absolute;
      bottom: -9px;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      @include background-image('index/modal/bar.png');
    }
  }
}
.p-bland__content {
  margin-inline: auto;
  margin-bottom: 137px;
  text-align: center;
  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(137);
  }
}
.p-bland__content--kv {
  display: flex;
  gap: 50px;
  max-width: 662px;
  @include max-screen($breackpoint_md) {
    gap: pxtovw_md(50);
  }
  img {
    max-width: 238px;
    @include max-screen($breackpoint_md) {
      max-width: pxtovw_md(238);
    }
  }
  p {
    font-size: 27px;
    text-align: left;
    @include max-screen($breackpoint_md) {
      font-size: pxtovw_md(27);
    }
  }
}
.p-bland__content--01 {
  img {
    max-width: 610px;
  }
}
.p-bland__content--02 {
  img {
    max-width: 730px;
  }
}
.p-bland__content--03 {
  @include max-screen($breackpoint_md) {
    width: calc(100% + 40px);
    margin-left: -20px;
    overflow: hidden;
  }
  img {
    max-width: 1060px;
    @include max-screen($breackpoint_md) {
      // width: calc(100% + pxtovw_md(310));
      // margin-left: pxtovw_md(-155);
      width: calc(100% + 120px);
      margin-left: -60px;
    }
  }
}
.p-bland__sub {
  position: relative;
  width: calc(100% + 40px);
  height: 350px;
  margin-bottom: 125px;
  margin-left: -20px;
  color: #fff;
  text-align: center;
  background-color: #6c7380;

  @include max-screen($breackpoint_md) {
    height: 40vw;
    min-height: 175px;
    margin-bottom: pxtovw_md(125);
  }

  img {
    position: absolute;
    inset: 0;
    width: auto;
    margin: auto;
    object-fit: contain;

    @include max-screen($breackpoint_md) {
      width: 100%;
    }
  }

  &[data='modal_sub_title01'] {
    box-shadow: 0 -7px 10px rgb(0 0 0 / 15%);
    img {
      height: 156px;

      @include max-screen($breackpoint_md) {
        // max-width: 469px;
        max-width: pxtovw_md(469);
        height: auto;
      }
    }
  }

  &[data='modal_sub_title02'] {
    img {
      height: 101px;

      @include max-screen($breackpoint_md) {
        max-width: pxtovw_md(522);
        height: auto;
      }
    }
  }

  &[data='modal_sub_title03'] {
    img {
      height: 114px;

      @include max-screen($breackpoint_md) {
        max-width: pxtovw_md(592);
        height: auto;
      }
    }
  }

  ._ribbon {
    position: absolute;
    top: -25px;
    bottom: auto;
    width: 71px;
    height: auto;
    transform: translateX(calc((469px / 2 + 30px) * -1));
    @include max-screen($breackpoint_md) {
      top: -5px;
      right: auto;
      left: 25px;
      width: 35px;
      transform: none;
    }
  }
}

.p-bland__sub-title {
  margin-bottom: 31px;
  font-size: 50px;
  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(31);
    font-size: pxtovw_md(50);
  }
}
.p-bland__sub-text {
  font-size: 30px;
  @include max-screen($breackpoint_md) {
    font-size: pxtovw_md(30);
  }
}

.p-bland__banner {
  max-width: 656px;
  padding: 50px 87px 56px 107px;
  margin-inline: auto;
  color: #fff;
  text-align: left;
  background-color: #6c7380;
  border-radius: 10px;
  @include max-screen($breackpoint_md) {
    padding: pxtovw_md(50) pxtovw_md(52) pxtovw_md(56) pxtovw_md(100);
    margin-bottom: pxtovw_md(31);
    font-size: pxtovw_md(50);
  }

  &::before {
    top: -10px;
    left: 20px;
    width: 72px;
    height: 127px;
    @include max-screen($breackpoint_md) {
      top: pxtovw_md(-10);
      left: pxtovw_md(20);
      width: pxtovw_md(72);
      height: pxtovw_md(127);
    }
  }
}
.p-bland__banner-title {
  margin-bottom: 35px;
  font-family: $f--gothic-stdn;
  font-size: 50px;
  line-height: 1.3;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(35);
    font-size: pxtovw_md(50);
  }

  span {
    display: block;
    font-family: filmotype-lacrosse, sans-serif;
    font-size: 85px;
    line-height: calc(65 / 80);
    @include max-screen($breackpoint_md) {
      font-size: pxtovw_md(85);
    }
  }
}
.p-bland__banner-sub-title {
  margin-bottom: 25px;
  font-family: $f--gothic-stdn;
  font-size: 30px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(25);
    font-size: pxtovw_md(30);
  }
}
.p-bland__banner-caption {
  margin-bottom: 50px;
  font-family: $f--gothic-stdn;
  font-size: 18px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(50);
    font-size: pxtovw_md(18);
  }
}
.p-bland__banner-list {
  margin-bottom: 46px;
  li {
    font-size: 18px;
    @include max-screen($breackpoint_md) {
      font-size: pxtovw_md(18);
    }
  }

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw_md(46);
    font-size: pxtovw_md(18);
  }
}
.p-bland__button {
  height: 168px;
  background-color: #6c7380;
  @include max-screen($breackpoint_md) {
    height: pxtovw_md(168);
  }

  .c-button__text {
    font-size: 18px;
    @include max-screen($breackpoint_md) {
      font-size: pxtovw_md(18);
    }

    &:last-of-type {
      font-size: 30px;
      @include max-screen($breackpoint_md) {
        font-size: pxtovw_md(30);
      }
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}

.p-bland__close {
  position: fixed;
  right: calc((100vw - 1230px) / 2 + 30px); // 30px = 右側のインナーの余白、1230px = インナーの最大幅
  bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  @include max-screen(1250px) {
    right: calc(5% + 30px);
  }
  @include max-screen($breackpoint_md) {
    inset: auto pxtovw_md(30) pxtovw_md(30) auto;
    gap: pxtovw_md(50);
  }
}

.p-bland__close-button {
  width: 60px;
  height: 60px;
  cursor: pointer;

  @include max-screen($breackpoint_md) {
    width: pxtovw_md(80);
    height: pxtovw_md(80);
  }
}

.p-bland__close-bar {
  width: 8px;
  height: 300px;
  background-color: #9f9f9f;
  border-radius: 25px;

  @include max-screen($breackpoint_md) {
    width: pxtovw_md(10);
    height: pxtovw_md(300);
  }
}
