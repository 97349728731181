@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-kv {
  position: relative;
  // overflow: hidden;
  @include max-screen($breackpoint_md) {
    padding-top: pxtovw(50);
  }

  // &::before {
  //   position: absolute;
  //   top: 242px;
  //   z-index: -100;
  //   width: 100%;
  //   height: 100%;
  //   content: '';
  //   background-size: contain;
  //   @include background-image('/index/kv/bg.png');

  //   @include max-screen($breackpoint_md) {
  //     @include background-image('/index/kv/sp/bg.png');
  //     top: pxtovw(390);
  //     background-size: contain;
  //   }

  //   @include min-screen(1500px) {
  //     background-size: cover;
  //   }
  // }
}

.kv-bg {
  position: absolute;
  top: 242px;
  z-index: -1;
  width: 100%;
  height: 875px;
  clip-path: polygon(0 40%, 100% 0, 100% 60%, 0 100%);
  background-color: $c--accent;

  @include max-screen($breackpoint_md) {
    top: pxtovw(436);
    height: pxtovw(430);
  }
}

.p-kv__container {
  display: flex;
  gap: 9px;
  justify-content: space-between;
  // margin-bottom: calc(90px - 16px);
  margin-bottom: 90px;

  @include max-screen($breackpoint_md) {
    gap: 0;
    padding-right: pxtovw(16);
    padding-left: pxtovw(16);
    margin-bottom: pxtovw(50);
  }
}

.p-kv__company-logo {
  width: 100%;
  margin: 0 auto;
  .splide__slide {
    width: fit-content !important;
  }
  .p-kv__splide {
    display: flex;
    align-items: center;
    min-height: 102px;
    margin-bottom: 20px;
    visibility: unset;
    background-color: #fff;
    @include max-screen($breackpoint_md) {
      min-height: pxtovw(67);
      margin-bottom: pxtovw(10);
    }
    .p-kv__track {
      width: auto;
      .p-kv__list {
        padding: 20px 0;
        @include max-screen($breackpoint_md) {
          padding: pxtovw(4, 0);
        }
        img {
          width: fit-content;
          max-height: 60px;
          object-fit: cover;
          @include max-screen($breackpoint_md) {
            width: auto;
            max-height: pxtovw(42);
          }
        }
      }
    }
  }
  .p-kv__company-comment {
    text-align: center;
    p {
      margin-bottom: 118px;
      font-size: 15px;
      line-height: 1;
      color: $c--white;
      @include max-screen($breackpoint_md) {
        margin-bottom: pxtovw(50);
        font-size: pxtovw(12);
        line-height: pxtovw(16);
        color: $c--black;
      }
    }
  }
}

.p-kv__contents {
  max-width: 65%;

  @include max-screen($breackpoint_md) {
    max-width: 100%;
  }
}
.p-kv__title {
  margin-bottom: 25px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(25);
  }
}
.p-kv__text {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(20);
    font-size: pxtovw(12);
  }
}
.p-kv__logo-wrap {
  margin-inline: auto;

  @include max-screen($breackpoint_md) {
    display: flex;
    align-items: flex-start;
    margin-bottom: pxtovw(21);
  }
}
.p-kv__logo {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @include max-screen($breackpoint_md) {
    flex-direction: column;
    gap: 0;
    width: pxtovw(154);
    margin-top: pxtovw(9);
  }
}
.p-kv__logo-desinare {
  width: 100%;
  max-width: 277px;
  @include max-screen($breackpoint_md) {
    width: pxtovw(154);
  }
}
.p-kv__logo-satisfaction {
  width: 100%;
  max-width: 160px;

  @include max-screen($breackpoint_md) {
    width: pxtovw(98);
  }
}
.p-kv__logo-n-kunn {
  display: none;

  @include max-screen($breackpoint_md) {
    display: block;
    width: pxtovw(172);
  }
}

.p-kv__button {
  max-width: 476px;
  height: 168px;
  margin-inline: auto;
  background-color: #fff;

  @include max-screen($breackpoint_md) {
    width: 100%;
    height: pxtovw(100);
  }
  .c-button__text {
    font-size: 30px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  .c-button__text--red {
    font-size: 18px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;
      @include background-image('_common/btn-polygon.png');

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}
.p-kv__n-kunn {
  max-width: 35%;
  padding-right: 55px;
  // margin-top: 30px;
  margin-top: auto;

  @include max-screen($breackpoint_md) {
    display: none;
  }
}
.p-kv__movie {
  margin-bottom: 100px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(50);
  }

  iframe {
    width: 100%;
    max-width: 900px;

    @include max-screen($breackpoint_md) {
      height: pxtovw(202);
    }
  }
}

.p-kv__modal-link {
  position: absolute;
  top: 565px;
  width: 175px;
  cursor: pointer;
  background-color: #6c7380;
  border-radius: 0 5px 5px 0;
  transition: all 0.3s ease;

  @include max-screen($breackpoint_md) {
    top: pxtovw(492);
    width: pxtovw(105);
  }

  &:hover {
    transition: all 0.3s ease;
    transform: scale(1.1);
  }
}

.p-kv__modal-button {
  display: block;
}
