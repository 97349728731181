@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン
.c-scroll-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  display: none;
  width: 80px;
  height: 80px;
  font-family: 'p22-underground', sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 4px $c--accent;

  @include max-screen($breackpoint_md) {
    position: fixed;
    right: pxtovw(16);
    bottom: pxtovw(10);
    display: none;
    width: pxtovw(48);
    min-width: 48px;
    height: pxtovw(48);
    min-height: 48px;
  }

  // 追従メニューへの変更
  &.is-active {
    position: fixed;
    inset: auto 0 0 auto;
  }

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 9px;
    font-size: 18px;
    font-weight: 600;
    color: $c--accent;
    text-align: center;
    @include max-screen($breackpoint_md) {
      padding: pxtovw(6);
      font-size: pxtovw(10);
    }

    &:hover {
      &::before {
        bottom: 23px;
        border-left-color: $c--accent;

        @include max-screen($breackpoint_md) {
          bottom: pxtovw(14);
        }
      }
      &::after {
        bottom: 18px;
        height: 10px;
        background: $c--accent;

        @include max-screen($breackpoint_md) {
          bottom: pxtovw(8);
          height: pxtovw(9);
        }
      }
    }

    &::before {
      /*絶対配置で矢印の位置を決める*/
      position: absolute;
      bottom: 33px;
      left: 49%;
      width: 6px;
      height: 6px;
      content: '';
      border-top: 2px solid $c--accent;
      border-right: 2px solid $c--accent;
      /*アニメーションの指定*/
      transition: all 0.2s linear;
      transform: rotate(-45deg) translateX(-50%);

      @include max-screen($breackpoint_md) {
        bottom: pxtovw(16);
        left: 48%;
        width: pxtovw(6);
        height: pxtovw(6);
        border-top: pxtovw(2) solid $c--accent;
        border-right: pxtovw(2) solid $c--accent;
      }
    }

    &::after {
      position: absolute;
      bottom: 15px;
      left: 50%;
      width: 2px;
      height: 20px;
      content: '';
      background: $c--accent;
      transition: all 0.2s linear;
      transform: translateX(-50%);

      @include max-screen($breackpoint_md) {
        bottom: pxtovw(4);
        width: pxtovw(2);
        height: pxtovw(16);
      }
    }
  }
}
