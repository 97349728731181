@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-arrow {
  /*矢印の基点とするためrelativeを指定*/
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s linear;

  &:hover {
    &::before {
      background: $c--accent;
    }
    &::after {
      border-left-color: $c--accent;
    }
  }

  &::before {
    position: absolute;
    content: '';
    background: $c--accent;
    transition: all 0.2s linear;
  }

  &::after {
    /*絶対配置で矢印の位置を決める*/
    position: absolute;
    content: '';
    border-right: 2px solid $c--accent;
    border-bottom: 2px solid $c--accent;
    /*アニメーションの指定*/
    transition: all 0.2s linear;
    transform: rotate(-45deg) translateY(-50%);
  }
}
