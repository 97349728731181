@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// カード

.c-cards {
}
.c-cards__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include max-screen($breackpoint_md) {
    flex-direction: column;
  }
}
.c-cards__card {
  border-radius: 15px;
  box-shadow: 1px 3px 10px rgba(#888888, 0.5);
}
