@charset 'utf-8';

// Header height
// **********************************************
$header_h_md: 70px;
$header_h_sm: 64px;

// BreackPoints
// @see https://tailwindcss.jp/docs/breakpoints;
// **********************************************
$breackpoint_sm: 360px;
$breackpoint_md: 768px;
$breackpoint_lg: 1024px;
$breackpoint_xl: 1280px;

// inner
$container: 1360px;
$inner: 1060px;

// Path
// **********************************************
$path_img: '../img/';

// Color
// **********************************************
$c--primary: #014c86;

$c--secondary: #f5df4d;

$c--black: #1e1e1e;
$c--white: #fff;

$c--accent: #eb0617;
$c--accent-btn: #eb1817; //ボタンの影用

$c--text: $c--black;
$c--link: $c--primary;

// font
$f--gothic-pr6n: 'p22-underground', 'toppan-bunkyu-gothic-pr6n', '游ゴシック', YuGothic, Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
$f--gothic-stdn: 'p22-underground', 'toppan-bunkyu-midashi-go-std', '游ゴシック', YuGothic, Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
