@charset 'utf-8';

@use '../foundation/global/_global' as *;

.l-footer {
  padding-top: 60px;
  padding-bottom: 47px;
  color: #fff;
  background: $c--black;
  @include max-screen($breackpoint_md) {
    padding-top: pxtovw(50);
    padding-bottom: pxtovw(83);
  }
}
.l-footer__container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;
  justify-content: space-between;
  @include max-screen($breackpoint_md) {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }
}
.l-footer__logo {
  width: 208px;
  @include max-screen($breackpoint_md) {
    width: pxtovw(148);
  }
  img {
    margin-bottom: 19px;
    @include max-screen($breackpoint_md) {
      margin-bottom: 51px;
    }
  }
}
.l-footer__copyright {
  font-size: 12px;

  @include max-screen($breackpoint_md) {
    font-size: pxtovw(10);
  }
}
.l-footer__nav {
  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(51);
  }
}
.l-footer__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 39px;

  span {
    padding: 0 1em;
  }

  @include max-screen($breackpoint_md) {
    flex-direction: column;
    gap: 1em;
    margin-bottom: pxtovw(75);
  }
}
.l-footer__list-item {
  font-size: 14px;
  @include max-screen($breackpoint_md) {
    font-size: pxtovw(12);
  }

  a {
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}
.l-footer__access {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  font-size: 12px;
  @include max-screen($breackpoint_md) {
    flex-direction: column;
    justify-content: start;
    font-size: pxtovw(10);
  }
}
