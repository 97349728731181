@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-tab {
}
.c-tab__menu-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;
}
.c-tab__menu {
}
.c-tab__contents {
}
.c-tab__content {
  display: none;

  &.is-show {
    display: block;
  }
}
