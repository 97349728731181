@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// ボタン

.c-fix-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: pxtovw(68);
  background-color: transparent;
}

.c-fix-menu__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-right: pxtovw(16);
  padding-left: pxtovw(16);
}

.c-fix-menu__button {
  width: pxtovw(160);
  height: pxtovw(48);
  font-size: pxtovw(12);
  background-color: #fff;
}
