@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section11 {
  .c-tab {
  }
  .c-tab__menu-wrap {
  }
  .c-tab__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 86px;
    font-family: $f--gothic-stdn;
    font-size: 30px;
    cursor: pointer;
    border: 1px solid #888888;
    border-radius: 86px 86px 0 0;

    @include max-screen(1024px) {
      font-size: 20px;
    }

    @include max-screen($breackpoint_md) {
      width: 45%;
      height: pxtovw(36);
      font-size: pxtovw(12);
      border-radius: pxtovw(36) pxtovw(36) 0 0;
    }

    &.is-active {
      border-bottom: 1px solid transparent;
    }

    &:first-of-type {
      @include max-screen($breackpoint_md) {
        width: 55%;
      }
    }
  }
}
.p-section11__title {
  height: 116px;
  margin-bottom: 75px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: auto;
    height: pxtovw(128);
  }
}
.p-section11__sub-title {
  height: 90px;
  margin-bottom: 75px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(50);
  }
}
.p-section11__contents {
  margin-bottom: 100px;

  @include max-screen($breackpoint_md) {
    margin-bottom: pxtovw(55);
  }
}

.p-section11__content {
  details {
    cursor: pointer;

    summary {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      padding-top: 25px;
      padding-bottom: 20px;
      font-family: $f--gothic-stdn;
      font-size: 18px;
      font-weight: 900;
      list-style: none;
      border-bottom: 1px dashed #888888;

      &::-webkit-details-marker {
        display: none;
      }

      @include max-screen($breackpoint_md) {
        padding-top: pxtovw(20);
        padding-bottom: pxtovw(20);
        font-size: pxtovw(12);
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 24px;
        width: 15px;
        height: 10px;
        content: '';
        background-size: cover;
        transform: translateY(-50%) rotate(-90deg);
        @include background-image('_common/btn-polygon.png');
        @include max-screen($breackpoint_md) {
          right: 0;
          width: pxtovw(9);
          height: pxtovw(6);
        }
      }
    }

    p {
      padding: 25px 24px;
      font-size: 14px;
      background-color: #f0f0f0;
      @include max-screen($breackpoint_md) {
        padding: pxtovw(20) pxtovw(16);
        font-size: pxtovw(12);
      }
    }
  }

  // 活性化時
  details[open] {
    summary {
      &::after {
        transform: translateY(-50%);
      }
    }
  }
}

.p-section11__content-title {
  @include max-screen($breackpoint_md) {
    padding-right: pxtovw(16);
  }
}

.p-section11__content-icon {
  font-family: josefin-sans, sans-serif;
  font-weight: 700;
}

.p-section11__button-wrap {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  margin-inline: auto;
  margin-bottom: 100px;

  @include max-screen($breackpoint_lg) {
    flex-direction: column;
    align-items: center;
  }

  @include max-screen($breackpoint_md) {
    gap: pxtovw(20);
    margin-bottom: pxtovw(66);
  }
}

.p-section11__button {
  width: 100%;
  max-width: 425px;
  height: 168px;

  @include max-screen($breackpoint_md) {
    max-width: pxtovw(330);
    height: pxtovw(100);
  }

  .c-button__text {
    font-size: 30px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  .c-button__text--red {
    font-size: 18px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;
      @include background-image('_common/btn-polygon.png');

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}
