@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// メインビジュアル

.p-section08 {
  margin-bottom: 125px;
}
.p-section08__title {
  height: 90px;
  margin-bottom: 75px;
  text-align: center;

  @include max-screen($breackpoint_md) {
    height: pxtovw(84);
    margin-bottom: pxtovw(50);
  }
}
.p-section08__sub-title {
  margin-bottom: 75px;
  font-size: 15px;
  text-align: center;
}
.p-section08__contents {
  margin-bottom: 97px;
}

.p-section08__button-wrap {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  margin-inline: auto;

  @include max-screen($breackpoint_lg) {
    flex-direction: column;
    align-items: center;
  }

  @include max-screen($breackpoint_md) {
    gap: pxtovw(20);
  }
}

.p-section08__splide {
  display: none;

  @include max-screen($breackpoint_md) {
    display: block;
    width: 100vw;
    height: 400px;
    margin: 0 calc(50% - 50vw) pxtovw(75);
    overflow: hidden;
  }
}
.p-section08__track {
  padding: 10px;
  margin-bottom: 75px;
  @include max-screen($breackpoint_md) {
    padding: 0;
    margin: 0;
  }
}
.p-section08__list {
  @include max-screen($breackpoint_md) {
    display: flex !important;
    flex-wrap: nowrap;
    height: 450px;
    padding: 10px 0 20px !important;
    overflow-x: auto;
    > * {
      position: relative;
      margin-left: 20px;
      &:last-of-type {
        &::after {
          position: absolute;
          top: 0;
          left: 100%;
          width: 20px;
          height: 1px;
          content: '';
          background-color: transparent;
        }
      }
    }
  }
}
.p-section08__slide {
  max-width: 193px;

  img {
    border-radius: 15px;
    box-shadow: 1px 3px 10px rgba(#888888, 0.5);
  }
}

.p-section08__pagination {
  bottom: -25px;
}

.p-section08__page {
  width: 11px;
  height: 11px;
  margin-inline: 8px !important;

  &.is-active {
    background: #333232;
    transform: scale(1) !important;
  }
}

.p-section08__button {
  width: 100%;
  max-width: 425px;
  height: 168px;

  @include max-screen($breackpoint_md) {
    max-width: pxtovw(330);
    height: pxtovw(100);
  }

  .c-button__text {
    font-size: 30px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(18);
    }
  }

  .c-button__text--red {
    font-size: 18px;

    @include max-screen($breackpoint_md) {
      font-size: pxtovw(12);
    }
  }

  .c-button__arrow {
    margin-bottom: 10px;

    &::before {
      bottom: -5px;
      width: 11px;
      height: 8px;
      @include background-image('_common/btn-polygon.png');

      @include max-screen($breackpoint_md) {
        bottom: -10px;
      }
    }
  }
}
