@charset 'utf-8';

@use '../../foundation/global/_global' as *;

.p-n-kunn {
  margin-inline: auto;
  max-width: 700px;

  @include max-screen($breackpoint_md) {
    max-width: 296px;
  }
}
